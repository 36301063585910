<template>
    <div class="search">
        <div style="padding: 14px 36px">
            <div class="search-box" v-if="visible">
                <template v-for="index in maxLevel">
                    <div v-if="(index > 1 && dataForm.itemCode.length >= indexLen[index - 1].len) || (index === 1)"
                         class="search-line" :key="'serviceItem' + index">
                        <div class="search-line-label">
                            <span>{{ indexLen[index].title }}</span>
                        </div>
                        <div class="search-line-content">
                            <div class="search-line-content-box">
                                <div style="flex: auto;max-width: 100%">
                                    <div class="content-box-select"
                                         :class="{'content-box-select-expand': expandOption['expand' + index]}">
                                        <span class="content-box-select-item"
                                              :class="{'on': serviceItemChoose['itemChoose' + index].itemCode === 'all'}"
                                              @click="itemClickListener(index, {itemCode: 'all', itemName: '全部'})">全部</span>
                                        <span
                                                class="content-box-select-item"
                                                :class="{'on': serviceItemChoose['itemChoose' + index].itemCode === item.itemCode}"
                                                v-for="item in itemDataList['dataList' + index]"
                                                :key="item.itemCode"
                                                @click="itemClickListener(index, item)"
                                        >
                      {{ item.itemName }}
                    </span>
<!--                                        <el-link style="position: absolute;top: 0;right: 0" type="primary"-->
<!--                                                 :underline="false" @click="switchExpand(index)">-->
<!--                                            {{ expandOption['expand' + index] ? '收起' : '展开' }}-->
<!--                                            <i :class="expandOption['expand' + index] ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>-->
<!--                                        </el-link>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="search-line">
                    <div class="search-line-label">
                        <span>服务方式</span>
                    </div>
                    <div class="search-line-content">
                        <div class="search-line-content-box">
                            <div style="flex: auto;max-width: 100%">
                                <div class="content-box-select">
                  <span
                          class="content-box-select-item"
                          :class="{'on': dict.dictId === dataForm.serviceMethod}"
                          v-for="dict in serviceMethodDict"
                          :key="dict.dictId"
                          @click="chooseMethod(dict.dictId)"
                  >
                      {{ dict.dictName }}
                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uf uf-ac" style="position: relative">
                    <div class="search-line-label" style="width: 62px">
                        <span>排序</span>
                    </div>
                    <div>
                        <el-radio-group v-model="dataForm.sortType" size="small" @change="searchData">
                            <el-radio-button label="">综合排序</el-radio-button>
                            <el-radio-button label="serviceCount">销量</el-radio-button>
                            <el-radio-button label="itemComment">评价</el-radio-button>
                            <el-radio-button label="createTime">时间</el-radio-button>
                        </el-radio-group>
                    </div>
                    <!--          <div class="search-line-label ml-5" style="margin-right: 5px">-->
                    <!--            <span>价格</span>-->
                    <!--          </div>-->
                    <!--          <div class="uf uf-ac search-price" style="position: relative;padding-right: 52px" @mouseenter="priceEnterVisible = true" @mouseleave="priceEnterVisible = false">-->
                    <!--            <el-input class="mr-1" style="width: 65px;text-align: left" size="mini" v-model="dataForm.priceLow" placeholder="￥" v-enter-int clearable></el-input>-->
                    <!--            <span>-</span>-->
                    <!--            <el-input class="ml-1" style="width: 65px;text-align: left" size="mini" v-model="dataForm.priceHigh" placeholder="￥" v-enter-int clearable></el-input>-->
                    <!--            <el-button v-show="priceEnterVisible" style="position: absolute;right: 0" type="primary" size="mini" @click="searchData">确认</el-button>-->
                    <!--          </div>-->
                    <!--          <div class="search-line-label ml-4" style="margin-right: 5px">-->
                    <!--            <span>服务地区</span>-->
                    <!--          </div>-->
                    <!--          <div>-->
                    <!--            <org-select-popover></org-select-popover>-->
                    <!--          </div>-->
                    <div class="uf uf-ac search-btn ml-3 uf-f1">
                        <el-input placeholder="请输入搜索内容" class="mr-2" v-model="dataForm.serviceName"
                                  @keyup.enter.native="getDataList(true)"></el-input>
                        <el-button type="primary" class="button" icon="el-icon-search" @click="getDataList(true)">搜索
                        </el-button>
                    </div>
                    <div class="uf uf-ac">
                        <el-divider direction="vertical"></el-divider>
                        <div class="ml-2">
                            共 <span class="fwb f18 c-base">{{ totalCount }}</span> 件产品
                        </div>
                        <div class="ml-3 mr-2">
                            <span class="c-base">{{ pageIndex }}</span>
                            <span>/</span>
                            <span>{{ totalPage }}</span>
                        </div>
                        <el-pagination
                                small
                                background
                                @size-change="sizeChangeHandle"
                                @current-change="currentChangeHandle"
                                :current-page="pageIndex"
                                :page-size="pageSize"
                                :total="totalCount"
                                layout="prev, next">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <div style="padding: 0 36px 20px 36px" v-loading="loading">
            <div class="list-box uf uf-pj fwrap-w tal">
                <template v-if="dataList.length > 0">
                    <service-card v-for="item in dataList" :service="item" @showDetail="chooseProduct(item)"
                                  :key="item.id"/>
                    <div class="zhanwei" style="width: 430px"></div>
                    <div class="zhanwei" style="width: 430px"></div>
                </template>
                <template v-else>
                    <div class="tac wi100" style="height: 300px">
                        <icon-svg class="mt-3" style="font-size: 200px" name="wushuju"></icon-svg>
                        <div class="f14" style="color: #909399">暂无数据</div>
                    </div>
                </template>
                <div class="tac wi100 mt-4 mb-2">
                    <el-pagination
                            background
                            @size-change="sizeChangeHandle"
                            @current-change="currentChangeHandle"
                            :current-page="pageIndex"
                            :page-sizes="[10, 20, 50]"
                            :page-size="pageSize"
                            :total="totalCount"
                            layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {checkIsNull} from '../../utils'
import routerTransfer from '../../utils/routerTransfer'
import ServiceCard from '../components/service-card'

export default {
    name: 'search',
    components: {
        ServiceCard
    },
    data() {
        return {
            visible: false,
            maxLevel: 1,
            expandOption: {},
            serviceItemChoose: {},
            serviceItemList: [],
            itemDataList: {},
            dataForm: {
                serviceName: '',
                itemCode: '',
                sortType: '',
                serviceMethod: '0',
                orgCode: '',
                priceLow: null,
                priceHigh: null
            },
            loading: false,
            dataList: [],
            pageIndex: 1,
            pageSize: 20,
            totalCount: 0,
            totalPage: 0,
            indexLen: {
                1: {len: 2, title: '行业分类'},
                2: {len: 4, title: '二级目录'},
                3: {len: 6, title: '三级目录'},
                4: {len: 8, title: '四级目录'},
                5: {len: 12, title: '五级目录'}
            },
            serviceMethodDict: [],
            priceEnterVisible: false
        }
    },
    computed: {
        keepAliveArr: {
            get() {
                return this.$store.state.common.keepAliveArray
            },
            set(arr) {
                this.$store.commit('common/updateKeepAliveArray', arr)
            }
        }
    },
    created() {
        this.$getDictList('SERVICE_METHOD', (dict) => {
            this.serviceMethodDict = dict.SERVICE_METHOD
        })
        this.dataForm.itemCode = this.$route.params.itemCode || ''
        this.dataForm.serviceName = this.$route.params.serviceName || ''
        this.getServiceItemMenu()
    },
    mounted() {
        routerTransfer.$on('searchEvent', function (data) {
            this.dataForm.serviceName = data.serviceName
            this.getDataList(true)
        }.bind(this))
        this.getDataList(true)
    },
    beforeRouteLeave(to, from, next) {
        if (to.name === 'service') {
            this.keepAliveArr = ['search']
        }
        next()
    },
    beforeDestroy() {
        routerTransfer.$off('searchEvent')
    },
    methods: {
        // 获取服务目录
        getServiceItemMenu() {
            // let menu = localStorage.getItem('serviceItemMenu')
            // if (!checkIsNull(menu)) {
            //     let menuObj = JSON.parse(menu)
            //     this.serviceItemList = menuObj.menu
            //     this.maxLevel = menuObj.maxLevel || 1
            //     this.handleExpandOption()
            //     this.visible = true
            //     return
            // }
            this.$http({
                url: this.$http.adornUrl(this.$api.PUB.MENU),
                method: 'get'
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.serviceItemList = data.menu.menu
                    // this.maxLevel = data.menu.maxLevel || 1
                    this.maxLevel = 1
                    // localStorage.setItem('serviceItemMenu', JSON.stringify(data.menu))
                    this.handleExpandOption()
                }
                this.visible = true
            }).catch(() => {
                this.visible = true
            })
        },
        // 处理服务类别条件栏
        handleExpandOption() {
            for (let i = 0; i < this.maxLevel; i++) {
                this.expandOption['expand' + (i + 1)] = false
                this.serviceItemChoose['itemChoose' + (i + 1)] = {itemCode: 'all', itemName: '全部'}
                this.itemDataList['dataList' + (i + 1)] = []
            }
            this.handleItem()
        },
        handleItem() {
            let itemCode = this.dataForm.itemCode
            // 处理每层选择的itemCode
            for (let i = 1; i <= this.maxLevel; i++) {
                if (!checkIsNull(itemCode) && itemCode.length >= this.indexLen[i].len) {
                    let thisItemCode = itemCode.substring(0, this.indexLen[i].len)
                    if (i === 1) {
                        this.serviceItemChoose['itemChoose' + i] = this.serviceItemList.filter(item => item.itemCode === thisItemCode)[0]
                    } else {
                        this.serviceItemChoose['itemChoose' + i] = this.serviceItemChoose['itemChoose' + (i - 1)].children.filter(item => item.itemCode === thisItemCode)[0]
                    }
                } else {
                    this.serviceItemChoose['itemChoose' + i] = {itemCode: 'all', itemName: '全部'}
                }
            }
            // 处理每层的数据
            for (let i = 1; i <= this.maxLevel; i++) {
                if (i === 1) {
                    this.itemDataList['dataList' + i] = this.$deepClone(this.serviceItemList)
                } else {
                    if (this.serviceItemChoose['itemChoose' + (i - 1)].itemCode !== 'all') {
                        this.itemDataList['dataList' + i] = this.serviceItemChoose['itemChoose' + (i - 1)].children
                    } else {
                        this.itemDataList['dataList' + i] = []
                    }
                }
            }
            this.$forceUpdate()
        },
        // 是否展开所有条件
        switchExpand(index) {
            this.expandOption['expand' + index] = !this.expandOption['expand' + index]
            this.$forceUpdate()
        },
        // 选择服务类别监听
        itemClickListener(index, item) {
            // 选择的全部
            if (index === 1 && item.itemCode === 'all') {
                this.dataForm.itemCode = ''
            } else if (item.itemCode === 'all') {
                this.dataForm.itemCode = this.serviceItemChoose['itemChoose' + (index - 1)].itemCode
            } else {
                if (item.itemCode === '800101') {
                    // window.open('http://model.smartquality.cn/')
                    return
                }
                this.dataForm.itemCode = item.itemCode
            }
            this.handleItem()
            this.searchData()
        },
        // 获取产品列表
        getDataList(refresh) {
            if (refresh) {
                this.pageIndex = 1
            }
            this.loading = true
            // let filter = "itemCode:"+ itemCode + "*,serviceMethod:" + serviceMethod
            this.$http({
                url: this.$http.adornUrl(this.$api.PRODUCT.SERVICE.SOLRLIST),
                methods: 'get',
                params: this.$http.adornParams({
                    // 'page': this.pageIndex,
                    // 'limit': this.pageSize,
                    // 'serviceName': this.dataForm.serviceName || null,
                    // 'itemCode': this.dataForm.itemCode || null ,
                    // 'orgCode': this.dataForm.orgCode || null,
                    // 'serviceMethod': this.dataForm.serviceMethod === 0 ? null : this.dataForm.serviceMethod,
                    // 'sortType': this.dataForm.sortType || null,
                    // 'priceLow': this.dataForm.priceLow || null,
                    // 'priceHigh': this.dataForm.priceHigh || null
                    'startPage': this.pageIndex - 1,
                    'pageSize': this.pageSize,
                    'keywords': this.dataForm.serviceName || null,
                    'itemCode': this.dataForm.itemCode || null,
                    'serviceMethod': this.dataForm.serviceMethod,
                    'sortType': this.dataForm.sortType,
                    'priceLow': this.dataForm.priceLow,
                    'priceHigh': this.dataForm.priceHigh
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    // let itemCode = localStorage.setItem('itemCode',this.dataForm.itemCode)
                    // this.dataList = data.page.list
                    // this.totalCount = data.page.totalCount
                    // this.totalPage = data.page.totalPage
                    this.dataList = data.result.list || []
                    this.totalCount = data.result.total || 0
                    this.totalPage = Math.ceil(data.result.total / this.pageSize) || 1
                } else {
                    this.dataList = []
                    this.totalCount = 0
                    this.totalPage = 1
                }
                this.loading = false
            })
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getDataList(true)
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getDataList(false)
        },
        chooseMethod(dictId) {
            this.dataForm.serviceMethod = dictId
            this.searchData()
        },
        searchData() {
            this.$nextTick(() => {
                this.getDataList(true)
            })
        },
        // 选择产品
        chooseProduct(item) {
            let itemCodeType = 'search'
            // this.keepAliveArr = ['search']
            this.$router.push({name: 'service', query: {id: item.id, itemCodeType: itemCodeType}})
        },
        //控制字数
        ellipsis(value) {
            if (!value) return ''
            if (value.length > 12) {
                return value.slice(0, 12) + '...'
            }
            return value
        }
    }
}
</script>

<style scoped>
.search-box {
    width: 1330px;
    margin: auto;
    padding: 24px;
    font-size: 14px;
    background: #ffffff;
    border: 1px solid #EBEEF5;
}

.search-line {
    display: flex;
    width: 100%;
    /*line-height: 2.4rem;*/
    margin-bottom: 16px;
    border-bottom: 1px solid #F2F6FC;
    padding-bottom: 11px;
}

.search-line-label {
    display: flex;
    margin-right: 10px;
    text-align: right;
}

.search-line-label span {
    display: inline-block;
    height: 32px;
    line-height: 32px;
}

.search-line-label span:after {
    content: '：';
}

.search-line .search-line-content {
    flex: 1 1;
    width: 100%;
}

.search-line-content-box {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 32px;
}

.content-box-select {
    padding-right: 50px;
    position: relative;
    max-height: 32px;
    margin-left: -8px;
    overflow: hidden;
    line-height: 32px;
    transition: all .3s;
}

.content-box-select-expand {
    max-height: 200px;
    transition: all .3s;
}

.content-box-select-item {
    box-sizing: border-box;
    display: inline-block;
    margin-right: 16px;
    padding: 0 8px;
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
}

.content-box-select-item:hover {
    color: #ff9123;
}

.content-box-select .on {
    border-radius: 3px;
    color: #fff;
    background: #ff9123;
}

.search-price >>> .el-input__inner {
    border-radius: 0;
    padding: 0 5px;
}

.search-price >>> .el-button--mini {
    padding: 7px 10px;
}

</style>
